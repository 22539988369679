import { goTo_geolocation } from '@lib/helperFunctions';
import { Close } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { Suspense, useCallback, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MapContext } from '../context/MapContext';
import { useDebounce } from '../hooks/useDebounce';
import { useSearchParams } from '../hooks/useSearchParams';
import { useSearchPayload } from '../hooks/useSearchPayload';
import { DEFAULT_ZOOM } from '../lib/constants';
import { createPopups } from '../lib/create-popups';
import { Colours } from '../theme';
import Tooltip from './Filter/Tooltip';
import { addCitiesAndDistrictsToMaps, chooseDesiredCityByPlz, chooseDesiredCityByName, getCitiesName } from '../lib/cityHelper';
import SearchResultTable, {
  SEARCH_RESULT_TABLE_WIDTH,
  TOP_OFFSET,
} from './SearchResultTable';
import { MEDIA } from './Ui';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    position: 'relative',
    padding: 6,
    flex: 1,
  },
  search: {
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '16',
    background: '#fff',
    color: 'rgb(0, 178, 176)',
    border: '1px solid rgb(0, 178, 176)',
    padding: '5px 10px',
    borderRadius: '24px',
    width: '90%',

    '& .MuiTypography-body1 ': {
      fontSize: '14px',
      paddingTop: '4px',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },

  searchCloseButton: {
    width: '12px',
    padding: 0,
    color: 'var(--dark-green)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const Search = ({ handleDrawerClose }) => {
  const searchParams = useSearchParams();
  const filtersArray = searchParams.filtersArray.filter(
    (item) => !item.includes('indeterminate')
  );

  const actualChecked = filtersArray.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});

  const selectedFilterSearchData = useSearchPayload(actualChecked);

  const history = useHistory();
  const location = useLocation();

  const [localSearch, setLocalSearch] = useState(searchParams.searchTerm);

  const [cities, setCities] = useState(null);
  const [searchTermAsCity, setSearchTermAsCity] = useState(null);

  useQuery({
    queryKey: ['citiesAndDistrictsOfGermany'],
    queryFn: async () => {
      const response = await fetch('/geodata/citiesAndDistrictsOfGermany.json');
      if (!response.ok) {
        throw new Error('Error while reading JSON file');
      }

      const data = await response.json();

      const allCitiesViaName = new Map();
      const allCitiesViaPlz = new Map();
      const allCitiesViaId = new Map();
      data.map(d => addCitiesAndDistrictsToMaps(d, allCitiesViaName, allCitiesViaPlz, allCitiesViaId));

      if (allCitiesViaName.size + allCitiesViaId.size + allCitiesViaPlz.size < 100000){
        console.log("Data missing after mapping Sets of cities:",
          allCitiesViaName.size, allCitiesViaId.size, allCitiesViaPlz.size);
      }

      setCities({allCitiesViaName, allCitiesViaPlz, allCitiesViaId});
      return { allCitiesViaName, allCitiesViaPlz };
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const debouncedSearchTerm = useDebounce({
    value: localSearch,
    delay: 150,
    callback: useCallback(
      (value) => {
        checkIfCityIsSearched(value, cities);

        const newQuery = new URLSearchParams(location.search);
        !!value.trim()
          ? newQuery.set('search', value)
          : newQuery.delete('search');
        setLocalSearch(value);
        history.replace({
          pathname: location.pathname,
          search: newQuery.toString(),
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [history, location.pathname, location.search, cities]
    ),
  });

  const currentSource = searchParams.source;
  const searchParamValue = searchParams.searchTerm;

  const [showNationals, setShowNationals] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const { map } = useContext(MapContext);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery(MEDIA);

  const { root, input, iconButton, search, searchCloseButton } = useStyles();

  const hasConditions =
    Object.values(selectedFilterSearchData).some((arr) => arr.length > 0) ||
    currentSource ||
    searchParamValue.length >= 3;

  const { data: filteredData, isFetching } = useQuery({
    queryKey: [
      'get-all-listings-data',
      selectedFilterSearchData,
      searchParamValue,
      currentSource,
    ],
    enabled: false, // disable query to prevent fetching data when component is mounted
  });

  const { data: fallbackData } = useQuery({
    queryKey: ['get-default-listings', DEFAULT_ZOOM],
    enabled: false,
  });

  const subscribedData = hasConditions
    ? filteredData || []
    : fallbackData || [];

  const queryClient = useQueryClient();

  const handleSearchSelection = (guid) => {
    if (guid) {
      axios
        .get(process.env.REACT_APP_API_URL + '/Angebote/angebot/' + guid)
        .then((resp) => {
          let koords = {
            lat: resp.data.latitude,
            lng: resp.data.longitude,
          };

          setSearchTerm(resp.data.name);

          // create popups according to search result
          // needed to create a new icon for the selected search result
          createPopups({
            map: map,
            data: subscribedData,
            searchResult: guid,
            onSearchResultClick: () => setShowSearchModal(false),
          });

          // close leaflet popup
          // we need to close the popup before we can goTo_geolocation() because popups are kept in view
          map.closePopup();

          goTo_geolocation(map, koords);
        });
    }
  };

  /* See if entered search term
  - is a city name (exact match)
  - is a Postleitzahl
  - is a number that could match a Postleitzahl
  otherwise set to 'null'. 
  
  Variable setSearchTermAsCity holds the found city so that later on you can jump to it.
  */
  function checkIfCityIsSearched(value, cities) {
    if (cities == null || value.length < 3) {
      setSearchTermAsCity(null);
      return;
    }

    const searchTermReduced = value.toLowerCase().trim();

    if (cities.allCitiesViaName.has(searchTermReduced)) {
      setSearchTermAsCity(chooseDesiredCityByName(cities.allCitiesViaName.get(searchTermReduced)));
    }
    else if (cities.allCitiesViaPlz.has(searchTermReduced)) {
      setSearchTermAsCity(chooseDesiredCityByPlz(cities.allCitiesViaPlz.get(searchTermReduced)));
    }
    else {
      setSearchTermAsCity(null);
    }
  }

  function resetSearch() {
    setShowSearchModal(false);
    setLocalSearch('');
    const newQuery = new URLSearchParams(location.search);
    newQuery.delete('search');
    history.replace({
      pathname: location.pathname,
      search: newQuery.toString(),
    });
  }

  const angeboteToShow = subscribedData
    ? subscribedData.filter((angebot) =>
        showNationals ? angebot.einsDeutschlandweit : true
      )
    : [];

  const reset = (guid) => {
    handleSearchSelection(guid);
    handleDrawerClose();
  };

  const onKeyUp = (event) => {
    if (event.key !== 'Enter' && event.key !== 'Escape') return;

    // enter key
    if (event.key === 'Enter') {
      event.preventDefault();
      jumpToLocationIfCityNameWasEntered();
    } else if (event.key === 'Escape') {
      // esc key
      event.preventDefault();
      resetSearch();
    }
  };

  function jumpToLocationIfCityNameWasEntered() {
    if (searchTermAsCity != null) {
      let koords = {
        lat: searchTermAsCity.lat,
        lng: searchTermAsCity.long,
      };
      goTo_geolocation(map, koords);
      
      const foundNameOfCity = getCitiesName(searchTermAsCity, cities.allCitiesViaId);
      foundNameOfCity && setLocalSearch(foundNameOfCity);
    }
  }

  const inputHandler = (event) => {
    const search_term = event.target.value ?? '';
    setLocalSearch(search_term);
    setShowSearchModal(true);
  };

  if (!map) return null;

  const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    border: '1px solid #ccc',
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Paper component='form' className={root}>
        <InputBase
          type='search'
          className={input}
          placeholder='Suchbegriff, Ort, Postleitzahl'
          inputProps={{ 'aria-label': 'suche' }}
          onChange={inputHandler}
          onKeyDown={onKeyUp}
          value={localSearch}
        />

        {searchTerm ? (
          <Box className={search}>
            <Typography noWrap>{searchTerm}</Typography>
            <IconButton
              onClick={() => {
                setSearchTerm('');

                //Create popups for all angebote again when search is reset
                // Needed to recluster the markers
                createPopups({ map: map, data: subscribedData });

                // close leaflet popup because clusters are back and popup might not represent the correct marker
                map.closePopup();
              }}
              className={searchCloseButton}
              aria-label='search-close'
              size='large'
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          <>
            <IconButton
              className={iconButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                resetSearch();
              }}
              aria-label='search'
              size='large'
            >
              {!!debouncedSearchTerm && (
                <Close color='primary' fontSize='small' mr={1} />
              )}
            </IconButton>
            <Box
              color={Colours._textGrey}
              display='flex'
              alignItems='center'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowSearchModal(true);
                queryClient.invalidateQueries({
                  queryKey: [
                    'get-all-listings-data',
                    selectedFilterSearchData,
                    searchParamValue,
                    currentSource,
                  ],
                });
              }}
            >
              <IconButton
                color={Colours._textGrey}
                sx={{
                  padding: 0,
                  '&:hover': {
                  backgroundColor: 'transparent',
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowSearchModal(true);
                  queryClient.invalidateQueries({
                  queryKey: [
                    'get-all-listings-data',
                    selectedFilterSearchData,
                    searchParamValue,
                    currentSource,
                  ],
                  });
                  jumpToLocationIfCityNameWasEntered();
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Paper>
      <Box component='label' display='flex' alignItems='center' mt={0.1}>
        <Checkbox
          checked={showNationals}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setShowSearchModal(isChecked);
            setShowNationals(isChecked);
          }}
          sx={{
            padding: '0',
            color: 'white',

            '&.Mui-checked': {
              color: 'white',
            },
          }}
          {...(isMobile
            ? {
                size: 'small',
              }
            : {})}
        />
        <Box
          component='span'
          color='white'
          ml={0.2}
          mt={0.8}
          sx={{
            ...(isMobile
              ? {
                  '&.MuiBox-root': {
                    fontSize: '.8rem',
                  },
                }
              : {}),
          }}
          {...(isMobile ? { fontSize: '.8rem' } : {})}
        >
          deutschlandweit
        </Box>
        <Suspense fallback={<div>..</div>}>
          <Tooltip name={'info'} color='white'>
            Nur die Hilfsangebote anzeigen, die deutschlandweit nutzbar sind.
            Also z.B. Online-Beratung, Telefon-Hotline.
          </Tooltip>
        </Suspense>
      </Box>
      {(isFetching && showSearchModal) ||
      !!debouncedSearchTerm ||
      showNationals ? (
        <Modal
          open={!isMobile} // open modal only on desktop
          onClose={() => {
            if (!isMobile) {
              setShowSearchModal(false);
            } else {
              resetSearch();
            }
          }}
          aria-labelledby='such-ergebnisse'
          aria-describedby='angebot-suche-ergebnisse'
          sx={{
            left: '360px',
            maxWidth: 'fit-content',
            '.MuiModal-backdrop': {
              left: '360px',
              backgroundColor: 'transparent',
              width: !showSearchModal ? '0' : '100%', //prevent backdrop from covering the map when search results are hidden
            },
          }}
        >
          <>
            <Box sx={style}>
              {showSearchModal && (
                <SearchResultTable
                  tabIndex='1'
                  data={angeboteToShow}
                  clickHandler={reset}
                  searchTerm={debouncedSearchTerm}
                  showNational={showNationals}
                  loading={isFetching}
                />
              )}
            </Box>
            <Button
              onClick={() => {
                setShowSearchModal((prev) => !prev);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                background: 'white',
                position: 'absolute',
                border: '1px solid #ccc',
                borderRadius: '0',
                minWidth: 'fit-content',
                padding: 0,
                width: 16,
                height: 24,
                justifyContent: 'center',
                left: showSearchModal ? SEARCH_RESULT_TABLE_WIDTH : 0, // width of search result table
                rotate: !showSearchModal ? '180deg' : '0',
                top: TOP_OFFSET + 24,
                '&:hover': {
                  background: 'white',
                },
              }}
            >
              <svg
                width='20'
                height='15'
                viewBox='0 0 8 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.390472 5.15244C-0.130157 5.62099 -0.130157 6.38193 0.390472 6.85048L5.72172 11.6485C6.10491 11.9933 6.67552 12.0945 7.17532 11.9071C7.67513 11.7197 8 11.2849 8 10.7976V1.20159C8 0.718038 7.67513 0.279471 7.17532 0.0920491C6.67552 -0.0953727 6.10491 0.00958326 5.72172 0.350691L0.390472 5.14869V5.15244Z'
                  fill='#666D7E'
                />
              </svg>
            </Button>
          </>
        </Modal>
      ) : null}
      {(isMobile && debouncedSearchTerm && showSearchModal) ||
      (isMobile && showNationals) ? (
        <SearchResultTable
          tabIndex='1'
          data={angeboteToShow}
          clickHandler={reset}
          searchTerm={debouncedSearchTerm}
          showNational={showNationals}
          loading={isFetching}
          closeResults={() => {
            setShowSearchModal(false);
            setShowNationals(false);
          }}
        />
      ) : null}
    </Box>
  );
};

export default React.memo(Search);
