import axios from 'axios';
import React from 'react';
import {
  BeratungIcon,
  FreizeitArbeitsangeboteIcon,
  PsychiatrischeBehandlungIcon,
  PsychiatrischeRehaIcon,
  SelbsthilfeIcon,
  TherapeutischeBehandlungIcon,
  WeiterbildungVernetzungIcon,
  WohnenIcon,
} from './filterIcons';

export const getDataByGuid = async (guid) => {
  const ANGEBOTE_BASE = `${process.env.REACT_APP_API_URL}/angebote/`;
  const url = `${ANGEBOTE_BASE}angebot/${guid}`;
  const resp = await axios.get(url);
  let json = JSON.stringify(resp.data);

  //eslint-disable-next-line
  json.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '');

  return JSON.parse(json);
};

/**
 * Fly to device position
 * @param {object} map - the map object
 * @param koords : []
 * @param zoom : number
 */
export function goTo_geolocation(map, koords, zoom) {
  switch (arguments.length) {
    case 1:
      flyToBrowerLocation(map, zoom);
      break;
    case 2:
      map.flyTo(koords, zoom);
      break;
    default:
      flyToBrowerLocation(map, zoom);
      break;
  }

  function flyToBrowerLocation(map, zoom) {
    var options = {
      enableHighAccuracy: false,
      timeout: 10000,
      maximumAge: 0,
    };

    function success(pos) {
      var crd = pos.coords;
      this.map.flyTo({ lat: crd.latitude, lng: crd.longitude }, zoom);
    }
    const suc = success.bind({ map: map });

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(suc, error, options);
  }
}

/**
 * Get visible Map Boundaries
 * @param {object} map - the map object
 */
export function get_map_bounds(map) {
  const b = map.getBounds();
  let bounds = {
    Bounds: [
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ],
  };
  bounds.zoom = map.getZoom();

  return bounds;
}

/** @ignore */
export function get_coords(e, coordinates) {
  // Ensure that if the map is zoomed out such that
  // multiple copies of the feature are visible, the
  // popup appears over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  return coordinates;
}

/**
 * Transform Data
 * @returns {string} JSON String
 * @param data
 */
export function transform_json(data) {
  const convert_coord = (coord) => {
    return parseFloat(coord).toFixed(12);
  };

  function Feature_Obj(properties, long, lat) {
    this.type = 'Feature';
    this.properties = properties;
    this.geometry = {
      type: 'Point',
      coordinates: [Number(convert_coord(long)), Number(convert_coord(lat))],
    };
  }

  const f = [];

  for (let i in data) {
    if (data.hasOwnProperty(i) && data[i].longitude !== 0) {
      f.push(new Feature_Obj(data[i], data[i].longitude, data[i].latitude));
    }
  }

  return { type: 'FeatureCollection', features: f };
}

export function getHostName(url) {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = 'https://' + url;
  }

  let match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);

  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    if (typeof match[1] === 'undefined') {
      return match[2];
    } else {
      return match[1] + match[2];
    }
  }

  return null;
}

export const getToLocation = ({ strasse, hausnummer, landkreis, plz, ort }) => {
  const fullAddress = `${strasse} ${hausnummer}, ${plz} ${ort}, ${landkreis}`;
  const encodedAddress = encodeURIComponent(fullAddress);
  const userAgent = navigator.userAgent;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    // The user is using an iOS device
    return `maps://?saddr=&daddr=${encodedAddress}`;
  } else if (userAgent.match(/Android/i)) {
    // The user is using an Android device
    return `geo:0,0?q=${encodedAddress}`;
  } else {
    // The user is using a different device
    return `https://www.openstreetmap.org/search?query=${encodedAddress}`;
  }
};

export const categoryDetailsMapping = {
  Beratung: {
    position: 1,
    icon: <BeratungIcon fontSize='small' />,
  },
  Selbsthilfe: {
    position: 2,
    icon: <SelbsthilfeIcon fontSize='small' />,
  },
  'Psychiatrische Behandlung': {
    position: 3,
    icon: <PsychiatrischeBehandlungIcon fontSize='small' />,
  },
  'Psychotherapeutische Behandlung': {
    position: 4,
    icon: <TherapeutischeBehandlungIcon fontSize='small' />,
  },
  'Weitere Behandlungsverfahren': {
    position: 5,
    icon: <TherapeutischeBehandlungIcon fontSize='small' />,
  },

  'Psychosomatische Reha': {
    position: 6,
    icon: <PsychiatrischeRehaIcon fontSize='small' />,
  },

  Weiterbildung: {
    position: 7,
    icon: <WeiterbildungVernetzungIcon fontSize='small' />,
  },
  'Freizeit- und Arbeitsangebote': {
    position: 8,
    icon: <FreizeitArbeitsangeboteIcon fontSize='small' />,
  },
  Wohnen: {
    position: 9,
    icon: <WohnenIcon fontSize='small' />,
  },
};

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function isNumeric(str) {
  return /^\d+$/.test(str);
}

export const KAT_NICE_NAMES = {
  Kat_Was: 'Kategorie',
  Kat_Konstellation: 'Konstellation',
  Kat_Ort: 'Orte',
  Kat_Zielgruppe: 'Zielgruppe',
  Kat_Barriere: 'Zugänglichkeit',
  Kat_Kosten: 'Kosten',
  Kat_Themen: 'Themen',
  Kat_Sprache: 'Sprache',
  Kat_Häufigkeit: 'Häufigkeit',
  Kat_PersonAlter: 'Alter',
  Kat_PersonRolleDetail: 'Personen Rollen Details',
  Kat_PersonRollen: 'Rolle',
  Kat_WasDetail: 'Unterkategorie',
};

/* Concatenate title and town - if listing has not already name of town in it. */
export function concatNameAndOrt (name, ort) {
  if (ort === undefined || ort.length === 0 || name.includes(ort)) {
    return name;
  }
  return `${name}, ${ort}`;
};
