import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import './App.css';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'leaflet/dist/leaflet.css';
import { Switch, useLocation } from 'react-router-dom';
import { Hinweis } from './components/Hinweis';
import { HomePoll } from './components/HomePoll';
import { Map } from './components/Map/Map';
import UI from './components/Ui';
import { MapContext } from './context/MapContext';

const queryClient = new QueryClient();

export function App() {
  const hideHinweis = window.localStorage.getItem('hideHinweis');
  const parsedHideHinweis = JSON.parse(hideHinweis);

  // deactivated atm (else:  hidePoll = window.localStorage.getItem('hidePoll'))
  const hidePoll = true;
  const parsedHideSurvey = JSON.parse(hidePoll);

  const location = useLocation();

  const isHome = location.pathname === '/home';

  // setting the actual leaflet map
  const [map, setMap] = useState(null);
  const mapContext = useMemo(() => ({ map, setMap }), [map, setMap]);

  //check if hideHinweis is a boolean and set the popupStatus accordingly to
  // the opposite since we want to show the popup if hideHinweis is false
  const hinweis = useMemo(() => {
    return typeof parsedHideHinweis === 'boolean' ? !parsedHideHinweis : true;
  }, [parsedHideHinweis]);

  const survey = useMemo(() => {
    return typeof parsedHideSurvey === 'boolean' ? !parsedHideSurvey : true;
  }, [parsedHideSurvey]);

  const [popupStatus, setpopupStatus] = useState(hinweis);
  const [pollPopupStatus, setPollPopupStatus] = useState(survey);
  const [pollComponent, setPollComponent] = useState();

  useEffect(() => {
    let timer;

    if (pollPopupStatus && !popupStatus && isHome) {
      timer = setTimeout(() => {
        setPollComponent(
          <HomePoll
            pollPopupStatus={pollPopupStatus && !popupStatus} // only show the poll if the hinweis is hidden to not show two popups at the same time an ovewhelming the user
            setPollPopupStatus={setPollPopupStatus}
          />
        );
      }, 20000);
    }

    return () => clearTimeout(timer);
  }, [pollPopupStatus, popupStatus, isHome]);

  const canShowPoll =
    pollPopupStatus && !popupStatus && isHome && pollComponent;

  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <MapContext.Provider value={mapContext}>
          {map ? <UI /> : null}

          <Map />
          <Hinweis popupStatus={popupStatus} setpopupStatus={setpopupStatus} />
          {canShowPoll ? pollComponent : null}
        </MapContext.Provider>
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
