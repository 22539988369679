import { createPopups } from '@lib/create-popups';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MapContext } from '../../context/MapContext';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useSearchPayload } from '../../hooks/useSearchPayload';
import { DEFAULT_ZOOM, FILTER_CATEGORIES_NAMES_MAP } from '../../lib/constants';
import { categoryDetailsMapping } from '../../lib/helperFunctions';
import { Colours } from '../../theme';
import Search from '../Search';
import { styles } from './FilterComponent-styles';
import { MainFilterTree } from './FilterTree';
import SidebarCard from './SidebarCard';
import { SubFilters } from './SubFilters';

export const katsWithoutDetails = ['Psychosomatische Reha'];

const Filter = (props) => {
  const { map } = useContext(MapContext);

  const { classes, handleDrawerClose, isMobile } = props;
  const history = useHistory();

  const { data, isFetching: isFetchingFilterCategories } = useQuery({
    queryKey: ['filter-categories'],
    queryFn: async () => {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + '/angebotedoc/werte'
      );

      let werte = response.data;

      const result = {};
      const allCategoriesGrouped = {};

      // Find all Kat_Was entries that are true in obj
      const mainEntries = werte
        .map((item) => {
          if (
            FILTER_CATEGORIES_NAMES_MAP[item.werteliste] ||
            item.werteliste === 'Kat_WasDetail'
          ) {
            //group the categories
            if (!allCategoriesGrouped[item.werteliste]) {
              allCategoriesGrouped[item.werteliste] = [];
            }

            //populate categories with only what we need
            const { bedingung1, bedingung2, ...rest } = item;

            (FILTER_CATEGORIES_NAMES_MAP[item.werteliste] ||
              item.werteliste === 'Kat_WasDetail') &&
              allCategoriesGrouped[item.werteliste].push(rest);
          }
          if (
            item.werteliste === 'Kat_Was' &&
            categoryDetailsMapping[item.wert]
          ) {
            return item;
          }

          return null;
        })
        .filter(Boolean);

      // For each main entry(Kat_Was), find its details and sub-items(Kat_WasDetail)
      mainEntries.forEach((mainEntry) => {
        // Find ALL corresponding sub-items (Kat_WasDetail) where bedingung1 matches main wert
        const subItems = werte.filter(
          (item) =>
            item.werteliste === 'Kat_WasDetail' &&
            item.bedingung1 === mainEntry.wert
        );

        // Create the structure for this entry
        result[mainEntry.wert] = {
          main: {
            details: {
              title: mainEntry.wert,
              info: mainEntry.infotext,
            },
          },
        };

        // Add sub-items(Kat_WasDetail) array if any found
        if (subItems.length > 0) {
          result[mainEntry.wert].sub = subItems.map((item) => ({
            title: item.wert,
            info: item.infotext,
            note: item.anmerkung,
          }));
        }
      });

      return { mainFilterData: result, allCategoriesGrouped };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const {
    searchParams,
    source,
    searchTerm,
    filtersArray: filtersArrayParams,
  } = useSearchParams();

  const currentSource = source;
  const searchParamValue = searchTerm;
  const filtersArray = filtersArrayParams.filter(
    (item) => !item.includes('indeterminate')
  );

  const actualChecked = filtersArray.reduce((acc, cur) => {
    acc[cur] = true;

    return acc;
  }, {});

  const [checked, setChecked] = useState(actualChecked);

  function handleCheck(checked) {
    setChecked(checked);
  }

  const selectedFilterSearchData = useSearchPayload(actualChecked);

  // if valid filters exist
  const hasConditions =
    Object.values(selectedFilterSearchData).some((arr) => arr.length > 0) ||
    currentSource ||
    searchParamValue.length >= 3;

  // All listings without filters
  const fallbackQuery = useQuery({
    queryKey: ['get-default-listings', DEFAULT_ZOOM],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/angebote/zoom/${DEFAULT_ZOOM}`
        );

        const activeAngebote = response.data.filter(
          (angebot) => angebot.einsAktiv
        );

        return activeAngebote;
      } catch (error) {
        alert('Fehler beim Datenabruf: ' + error);
        console.error('Fehler beim Datenabruf:', error);
        return [];
      }
    },
    enabled: !hasConditions && !isFetchingFilterCategories,
    // This makes sure the query data persists and doesn't refetch unnecessarily
    staleTime: 1000 * 60 * 60 * 24, //one day
    cacheTime: 1000 * 60 * 60 * 24,
  });

  const mainQuery = useQuery({
    queryKey: [
      'get-all-listings-data',
      selectedFilterSearchData,
      searchParamValue,
      currentSource,
    ],
    queryFn: async () => {
      if (isFetchingFilterCategories) {
        return [];
      }

      if (hasConditions) {
        try {
          const actualKatWas = selectedFilterSearchData?.kat_Was?.filter(
            (kat) => katsWithoutDetails.includes(kat)
          );

          const countResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/angebote/angebotmapviewmodelscount/`,
            {
              ...selectedFilterSearchData,
              kat_Was: actualKatWas ?? [],
              quelle: currentSource,
              ...(searchParamValue.length >= 3 && {
                searchString: searchParamValue,
              }),
            }
          );

          const count = countResponse.data;
          const body = {
            angebotFilter: {
              ...selectedFilterSearchData,
              kat_Was: actualKatWas ?? [],
            },
            take: count,
            ...(searchParamValue.length >= 3 && {
              searchString: searchParamValue,
            }),
          };

          if (currentSource && currentSource.length > 0 && count > 0) {
            body.quelle = currentSource;
          }

          const dataResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/angebote/angebotmapviewmodels`,
            body
          );

          const angebotData = dataResponse.data;
          const activeAngebote = angebotData.filter(
            (angebot) => angebot.einsAktiv
          );

          return activeAngebote;
        } catch (error) {
          alert('Fehler beim Datenabruf: ' + error);
          console.error(error);
          return [];
        }
      }
      // If conditions aren't met, use the fallback data (no filters)
      return fallbackQuery?.data || [];
    },

    enabled: hasConditions,
  });

  useQuery({
    queryKey: [
      'recreate-popups',
      selectedFilterSearchData,
      searchParamValue,
      currentSource,
      fallbackQuery,
      mainQuery,
    ],
    queryFn: async () => {
      const listings = !!hasConditions
        ? mainQuery.data
        : fallbackQuery.data || [];

      createPopups({
        map,
        data: listings,
        history,
      });

      return [];
    },
  });

  const hasActiveFilters = filtersArray.length > 0;

  return (
    <>
      {!isMobile && <Search handleDrawerClose={handleDrawerClose} />}

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginTop={1.5}
        marginBottom={0.5}
      >
        <Typography variant='h5' classes={{ root: classes.h5 }}>
          Filter {hasActiveFilters && `(${filtersArray.length})`}
        </Typography>
        {hasActiveFilters && (
          <Button
            onClick={() => {
              searchParams.delete('filters');
              setChecked({});
              history.replace({ search: searchParams.toString() });
            }}
            sx={{
              paddingY: '0',
              color: Colours._lightGrey,
              fontSize: '.8rem',
            }}
          >
            <span>alle Filter löschen</span>
            <CloseIcon
              fontSize='small'
              sx={{
                fontSize: '.9rem',
                color: Colours._lightGrey,
              }}
            />
          </Button>
        )}
      </Box>

      <SidebarCard classes={classes.btmBox}>
        <Box mb={1}>
          {data?.mainFilterData && (
            <MainFilterTree
              data={data.mainFilterData}
              checked={checked}
              handleCheck={handleCheck}
            />
          )}
        </Box>

        {data?.allCategoriesGrouped ? (
          <SubFilters
            allCategoriesGrouped={data.allCategoriesGrouped}
            selectedFilterSearchData={selectedFilterSearchData}
            checked={checked}
            handleCheck={handleCheck}
          />
        ) : null}
      </SidebarCard>
    </>
  );
};

export default React.memo(withStyles(styles)(Filter));
