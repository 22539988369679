function hasSufficientData(city) {
  return city.plz.length > 0 && hasCoordinates(city);
}

/* Remove end of 'Menden, Sauerland' or 'Kirchzarten (Breisgau)' */
function removeNameSuffix(cityName) {
  return cityName.split(',')[0].split('(')[0].split('/')[0].trim();
}

function isTown(city) {
  return city.level != null && (city.level === 5 || city.level === 6);
}

function isDistrict(city) {
  return city.level != null && city.level > 6;
}

function hasCoordinates(city) {
  return city.lat !== undefined && city.lat > 0 && city.long !== undefined && city.long > 0;
}

export function addCitiesAndDistrictsToMaps(city, mapViaName, mapViaPlz, mapViaId) {
  const mappedCity = {
    id: String(city.id),
    name: String(city.na),
    plz: String(city.pl),
    lat: Number(city.la),
    long: Number(city.lo),
    size: Number(city.si),
    kz: String(city.kz),
    level: Number(city.le),
    of: String(city.of)
  };
  const nameLower = mappedCity.name.toLowerCase();
  const originalId = String(mappedCity.id);

  if (!hasSufficientData(mappedCity)){
    return;
  }

  // enrich data of previous found city that is a Landeshauptstadt or Bundesland itself
  if (isTown(mappedCity) && mapViaId.has(mappedCity.of) && isTown(mapViaId.get(mappedCity.of))) {
    const prevEntry = mapViaId.get(mappedCity.of);
    const prevId = String(prevEntry.id);
    prevEntry.plz = (mappedCity.plz.length > 0 ? mappedCity.plz : prevEntry.plz);
    prevEntry.long = (mappedCity.long > 1 ? mappedCity.long : prevEntry.long);
    prevEntry.lat = (mappedCity.lat > 1 ? mappedCity.lat : prevEntry.lat);
    prevEntry.id += (prevId.includes(originalId) ? '' : "," + originalId);
    
    mappedCity.plz = (prevEntry.plz.length > 0 ? mappedCity.plz : prevEntry.plz);
    mappedCity.long = (prevEntry.long > 1 ? mappedCity.long : prevEntry.long);
    mappedCity.lat = (prevEntry.lat > 1 ? mappedCity.lat : prevEntry.lat);
    mappedCity.id += (originalId.includes(prevId) ? '' : "," + prevId);
  }

  // map Set via id
  mapViaId.set(originalId, mappedCity);

  // map Set via plz
  if (mappedCity.plz.length > 4){
    const plzSplitted = mappedCity.plz.split(',');
    for (let i=0; i<plzSplitted.length; i++){
      const plz = plzSplitted[i].trim();
      if (!mapViaPlz.has(plz)) {
        mapViaPlz.set(plz, []);
      }
      mapViaPlz.get(plz).push(mappedCity);
    }
  }

  // map Set via name
  if (!mapViaName.has(nameLower)) {
    mapViaName.set(nameLower, []);
    mapViaName.get(nameLower).push(mappedCity);
  }
  else {
    const prevEntry = mapViaName.get(nameLower)[0];
    if (mappedCity.of === prevEntry.id || mappedCity.kz === prevEntry.kz) {
      prevEntry.plz = (mappedCity.plz.length > 0 ? mappedCity.plz : prevEntry.plz);
      prevEntry.long = (mappedCity.long > 1 ? mappedCity.long : prevEntry.long);
      prevEntry.lat = (mappedCity.lat > 1 ? mappedCity.lat : prevEntry.lat);
      prevEntry.id += (prevEntry.id.includes(mappedCity.id) ? '' : "," + mappedCity.id);
    }
    else {
      mapViaName.get(nameLower).push(mappedCity);
    }
  }  
}

/* Find a greedy district/city via matching PLZ or calculate middle point via long/lat sum. */
export function chooseDesiredCityByPlz(list) {
  let latSum = 0;
  let longSum = 0;
  let sumCounter = 0;
  let exampleDistrict = null;

  for (const city of list) {
    // check if PLZ is the only one of a city/district
    if (city.plz.length === 5){
      return city;
    }

    // collect lats and longs
    if (isDistrict(city) && hasCoordinates(city)){
      latSum += city.lat;
      longSum += city.long;
      sumCounter += 1;
      exampleDistrict = city;
    }
  }

  if (sumCounter > 0){
    const imaginedCity = {
      id: String(exampleDistrict.id),
      name: String(exampleDistrict.name),
      plz: String(exampleDistrict.plz),
      lat: Number(latSum / sumCounter),
      long: Number(longSum / sumCounter),
      size: Number(exampleDistrict.size),
      kz: String(exampleDistrict.kz),
      level: Number(exampleDistrict.level),
      of: String(exampleDistrict.of)
    };
    return imaginedCity;
  }

  return list[0];
}

/* Precondition: a search string was entered.
Now find the most relevant item - this should be the town in the list. */
export function chooseDesiredCityByName(list) {
  if (list.length === 1) {
    return list[0];
  }

  for(const city of list){
    if (isTown(city)){
      return city;
    }
  }
  return null;
}

/* Get cities name to be displayed in the search bar.
When a district was searched for then use (main) city name. */
export function getCitiesName(city, mapViaId) {
  if (isTown(city)) {
    return removeNameSuffix(city.name);
  }
  while(isDistrict(city)){
    city = mapViaId.get(city.of);
    if (isTown(city) && hasSufficientData(city)) {
      return removeNameSuffix(city.name);
    }
  }

  return null;
}
