import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';
import { MapContext } from '../context/MapContext';
import { concatNameAndOrt } from '../lib/helperFunctions';
import { Colours } from '../theme';
import { SearchModal } from './MapPopup/SearchModal';
import { MEDIA } from './Ui';

export const TOP_OFFSET = 93;
export const SEARCH_RESULT_TABLE_WIDTH = '320px';

export default function SearchResultTable({
  data,
  clickHandler,
  searchTerm,
  loading,
  closeResults,
  showNational,
}) {
  const [searchedAngebot, setSearchedAngebot] = useState({
    show: false,
    guid: '',
  });
  const { map } = useContext(MapContext);
  function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    highlight = highlight.replace(/\)/g, '\\)').replace(/\(/g, '\\(').replace(/\./g, '\\.');

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <Box component='span' color={Colours._deepGreen} fontWeight={500}>
        {parts.length > 0
          ? parts.map((part, i) => (
              <Fragment key={i}>
                {part?.toLowerCase() === highlight?.toLowerCase() ? (
                  <b
                    style={{
                      color: 'white',
                      background: Colours._deepGreen,
                    }}
                  >
                    {part}
                  </b>
                ) : (
                  part
                )}
              </Fragment>
            ))
          : null}
      </Box>
    );
  }

  const isMobile = useMediaQuery(MEDIA);

  return (
    <Box
      display='flex'
      flexDirection='column'
      position='absolute'
      bgcolor='white'
      component='ul'
      aria-label='such ergebnisse'
      boxShadow={2}
      sx={{
        overflowY: 'auto',
        zIndex: 9999,
        height: isMobile ? '60vh' : '75vh',
        listStyle: 'none',
        marginLeft: 0,
        paddingLeft: 1,
        minWidth: isMobile ? '70vw' : SEARCH_RESULT_TABLE_WIDTH,
        border: '1px solid #D9D9D9',
        color: '#000000de',
      }}
      top={isMobile ? 54 : TOP_OFFSET}
      p={1}
    >
      {searchTerm?.length < 3 && !showNational ? (
        <Box>Suchbegriff muss größer als 2 Zeichen sein</Box>
      ) : loading ? (
        <Typography>Lade Ergebnisse...</Typography>
      ) : data && data.length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between'>
            <span>
              <b>{data.length}</b> Ergebnisse
            </span>
            {isMobile && (
              <Button
                onClick={() => closeResults()}
                sx={{
                  minWidth: 'fit-content',
                  padding: 0,
                }}
              >
                <CloseIcon
                  fontSize='small'
                  sx={{
                    color: Colours._textGrey,
                  }}
                />
              </Button>
            )}
          </Box>

          <Box>
            {data
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((row, i) => {
                const kats = row.katWasSum.split(',');

                return (
                  <Box
                    py={1}
                    sx={{
                      cursor: 'pointer',
                    }}
                    key={row.guid}
                    component='li'
                    borderBottom={`1px solid #D9D9D9`}
                  >
                    <Box
                      onClick={() => {
                        if (!row.latitude || !row.longitude || showNational) {
                          setSearchedAngebot({ show: true, guid: row.guid });
                          map.closePopup();
                          return;
                        }

                        clickHandler(row.guid);
                      }}
                    >
                      {getHighlightedText(
                        concatNameAndOrt(row.name, row.ort),
                        searchTerm
                      )}
                    </Box>
                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                      {kats.map((kat, index) => (
                        <Fragment key={`${kat}-${index}`}>
                          <Box
                            component='span'
                            fontSize='12px'
                            minWidth='fit-content'
                          >
                            {kat}
                          </Box>
                          {/* check if we are NOT on the last index */}
                          {kats.at(-1) !== kat && (
                            <Box
                              mx={1}
                              fontSize='8px'
                              color={Colours._textGrey}
                            >
                              {/* use a bullet point dot */}
                              &#8226;
                            </Box>
                          )}
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </>
      ) : (
        <Box>Keine Ergebnisse</Box>
      )}

      <SearchModal
        searchedAngebot={searchedAngebot}
        handleClose={() => setSearchedAngebot({ show: false, guid: '' })}
      />
    </Box>
  );
}
